import React from 'react'

const NotFound = () => {
  
  const messageStyle = {
    padding: '40px 15px',
    textAlign: 'center'
  }

  return (
      <div style={messageStyle}>
        <h2>Oops..</h2>
        <p>The requested page does not exist.</p>
      </div>
  )
}

export default NotFound
