import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Login from './Login';
import NotFound from './NotFound';
import './App.css';

class App extends Component {
  render() {
    return (
      <div>
        <Router basename='/'>
          <div>
              <Switch>
                <Route exact path='/' render={() => <Login />}/>
                <Route path="*" component={ NotFound }/>
              </Switch>
          </div>
        </Router>
      </div>
    );
  }
}

export default App
