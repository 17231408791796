import React from 'react';
import { Alert } from 'reactstrap';
import './notification.css';

const Notification = ({ message, error, url }) => {
  if (message === null) {
    return null

  } else if (error) {
    return (
        <Alert color="danger">
        <h4 className="alert-heading">Identification failed!</h4>
        <hr />
        <p className="alert-heading"><b>{ message }</b></p>
        </Alert>
    )
  } else {
    return (
        <Alert color="success">
          <h4 className="alert-heading">Identification successful!</h4>
          <hr />
          <p>Complete the identification by clicking <a href={ url } className="alert-link">here</a></p>
        </Alert>
    )
  }
}

export default Notification
