import React, { Component } from "react";
import { Container, Col, Form, FormGroup, Label, Input, Button } from "reactstrap";
import Cookies from 'universal-cookie';
import Notification from './Notification'
import loginService from '.././services/login';
import 'bootstrap/dist/css/bootstrap.min.css';
import './login.css';
import Moneytee from "./logos/Moneytee";
import Fraktio from "./logos/Fraktio";

export default class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userId: "",
      password: "",
      isHidden: false,
      error: false,
      message: null
    };
  }

  validateForm() {
    return this.state.userId.length > 0 && this.state.password.length > 0;
  }

  returnButtonVisibility() {
    return this.state.isHidden === true;
  }

  handleChange = async (event) => {
    await this.setState({
      [event.target.name]: event.target.value
    });
  }

  handleSubmit = async (event) => {
    event.preventDefault();

    const cookies = new Cookies();
    const cookie = cookies.getAll()

    const result = await loginService.login(this.state.userId, this.state.password, cookie)

    if (!result.success === true) {
      this.setState({
        userId: "",
        password: "",
        message: result.message,
        error: true
      })
      this.timeoutForNotification(7000)
      return null
    }

    this.setState({
       userId: "",
       password: "",
       isHidden: true,
       message: result.message,
       error: false,
       url: result.returnUrl
     })
  }

  timeoutForNotification = (seconds) => {
    setTimeout(() => {
      this.setState({ message: null })
    }, seconds)
  }

  render() {
    const { userId, password } = this.state;
    return (
    <>
      <Fraktio/>
      <Moneytee/>
      <Container className="Login">
        <Notification message={this.state.message} error={this.state.error} url={this.state.url} />
        {!this.state.isHidden && (
          <div>
            <h2>Mock identification</h2>
            <Form className="form" onSubmit={ (e) => this.handleSubmit(e) }>
              <Col>
                <FormGroup>
                  <Label for="userId">Username</Label>
                  <Input autoFocus type="text" name="userId" value={ userId } onChange={this.handleChange} />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label for="password">Password</Label>
                  <Input type="password" name="password" value={ password } onChange={this.handleChange} />
                </FormGroup>
                <Button color="info" disabled={!this.validateForm()}>Login</Button>
              </Col>
            </Form>
          </div>
        )}
      </Container>
     </>
    );
  }
}
