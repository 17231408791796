import axios from 'axios'
const baseUrl = '/api/login'

const login = async (userId, password, cookie) => {
  try {
    const credentials = {
      "userId": userId,
      "password": password,
      "B02K_STAMP": cookie.B02K_STAMP,
      "B02K_KEYVERS": cookie.B02K_KEYVERS,
      "B02K_ALG": cookie.B02K_ALG,
      "A01Y_RETLINK": cookie.A01Y_RETLINK
    }
    const response = await axios.post(baseUrl, credentials)

    return response.data

  } catch (exception) {
    return { success: false, message: "Invalid username or password!" }
  }

}

export default { login }
